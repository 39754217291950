
import { namespace } from 'vuex-class'
import { Vue, Component, Prop } from 'vue-property-decorator'
import * as sq from '~/store/modules/sq'
import { Carrier } from '~/store/interfaces'

const SQ = namespace(sq.name)

@Component({
  components: {
    AddressSearchBig: () => import('~/components/AddressSearchBig.vue'),
    AdvancedSearch: () => import('~/components/AdvancedSearch.vue'),
    LoadingSpinner: () => import('~/components/Icons/LoadingSpinner.vue')
  },
  data() {
    return {
      siteId: process.env.siteId,
      brand: process.env.brand
    }
  }
})
export default class ServiceQualificationBig extends Vue {
  @Prop({ default: null })
  readonly carrier: Carrier | null

  @SQ.Getter
  sq

  @SQ.Getter
  addressChecked

  @SQ.Getter
  address

  @SQ.Action
  serviceQualification

  @SQ.Getter
  primaryNetwork

  @SQ.Mutation
  setAdvancedSearch

  @SQ.Getter
  locationId

  loading = false

  advancedSearch = false

  toggleAdvancedSearch() {
    this.advancedSearch = !this.advancedSearch
  }

  async runSqCheck() {
    await this.setAdvancedSearch(false)
    this.loading = true
    await this.serviceQualification()
    this.loading = false

    this.$router.push({
      path: '/signup'
    })
  }
}
